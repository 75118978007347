import React, { useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'

import { convertRate, xirr, RateInterval } from 'node-irr'
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import {
  GetCompanyWiseDataQuery,
  CompanyDataInput,
  CompanyTypes,
  useGetCompanyWiseDataQuery,
  useSaveInvestmentModellingMutation,
  SaveInvestmentModellingMutation,
  SaveInvestmentModellingMutationVariables,
  useGetInvestmentModellingQuery,
  GetInvestmentModellingQuery,
  useGetInvestorIrrTransactionsQuery,
  GetInvestorIrrTransactionsQuery,
  useGetAllExitModellingQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { calculateXirr } from '../../../../utils/Utils'
import { useIMDataStore } from '../components/socketStore'
import { GlobalContextType, GlobalContext } from '../../store/context/globalContext'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import EmailInvestmentModelling from '../components/EmailInvestmentModlling'
import Toast, { ToastType } from '../../../../utils/toast'
import { ExitModellingDataModel } from '../components/InvestmentModellingHelper'
import { InputBox } from '../../../../utils/NumberInputBox'

export default function ExitModellingPage() {
  const [initialMultiplier, setInitialMultiplier] = useState<number[]>([8, 9, 10, 11])
  const { IMData: exitModelling, setIMData: setExitModelling, sendMessage } = useIMDataStore()

  // const [numberOfColumns, setNumberOfColumns] = useState<number>(0) // Initially 2 columns

  const [moic, setMoic] = useState<number>(0)
  const [irr, setIrr] = useState<number>(0)
  const [showModal, setShowModal] = useState(false)
  const [isEditName, setIsEditName] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isDateEmpty, setIsDateEmpty] = useState(true)
  const [activeIndex, setActiveIndex] = useState(-1)
  const [activeCurrent, setActiveCurrent] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  let initialData: any = location.state || undefined
  const modelId: number = (initialData && initialData.modellingDataId) || 0
  const [modelName, setModelName] = useState<string>(
    (initialData && initialData.modelName) || 'Model 1'
  )
  const [newModelName, setNewModelName] = useState<string>(
    (initialData && initialData.modelName) || 'Model 1'
  )

  const { state } = useContext<GlobalContextType>(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const URL = process.env.REACT_APP_SOCKET_URL || 'ws://localhost:4000/'
  const socket = io(URL)
  const { companyType, id } = useParams()
  let invId = ''

  const mutateSaveModelling = useSaveInvestmentModellingMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SaveInvestmentModellingMutation,
        _variables: SaveInvestmentModellingMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: { hissaCompanyId: id },
        }
        setLoading(false)
        queryClient.invalidateQueries(useGetAllExitModellingQuery.getKey(compKey))
        queryClient.invalidateQueries(useGetInvestmentModellingQuery.getKey())
        Toast('Exit Modelling Saved Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  useEffect(() => {
    socket.on('message', (data) => {
      setExitModelling(JSON.parse(data))
    })
  }, [exitModelling])

  useEffect(() => {
    if (initialData) {
      socket.on('message', (data) => {
        setExitModelling(JSON.parse(data))
      })
    }
  }, [initialData])

  const compType = companyType as CompanyTypes
  let hissaCompanyId = ''
  let companyId = 0
  compType === CompanyTypes.Hissa ? (hissaCompanyId = id || '') : (companyId = Number(id) || 0)

  const input: CompanyDataInput = {
    type: compType,
    hissaCompanyId: hissaCompanyId,
    id: companyId,
  }

  const { data: companyData } = useGetCompanyWiseDataQuery<GetCompanyWiseDataQuery, Error>(
    graphqlRequestClient,
    {
      input: input,
    },
    {}
  )

  const { data: investorIrrTransactionsData } = useGetInvestorIrrTransactionsQuery<
    GetInvestorIrrTransactionsQuery,
    Error
  >(
    graphqlRequestClient,
    {
      input: input,
    },
    {}
  )

  const { data: investmentModellingDetails, isLoading } = useGetInvestmentModellingQuery<
    GetInvestmentModellingQuery,
    Error
  >(
    graphqlRequestClient,
    { input: { hissaCompanyId, modelId: Number(initialData?.modellingDataId) || 0 } },
    {
      enabled: modelId > 0 ? true : false,
      cacheTime: 0,
      staleTime: 0,
    }
  )

  const previousModelling = investmentModellingDetails?.getInvestmentModellingDetails
  useEffect(() => {
    if (previousModelling && previousModelling.investmentModellingDetails && modelId > 0) {
      if (companyData) {
        if (previousModelling.investmentModellingDetails.currentInvestmentDetails)
          previousModelling.investmentModellingDetails.currentInvestmentDetails.investment =
            getCurrentInvestment
      }
      sendMessage(previousModelling?.investmentModellingDetails as ExitModellingDataModel)
      setExitModelling(previousModelling.investmentModellingDetails as ExitModellingDataModel)
      const activeIndex = previousModelling.investmentModellingDetails?.tableDetails.findIndex(
        (round: any) => round.exitHere
      )
      setActiveIndex(activeIndex)
      setActiveCurrent(activeIndex === -1)
    }
  }, [previousModelling])

  const investorIdList: number[] = []
  companyData?.getCompanyData?.investorData?.forEach((investor) => {
    if (investor?.exited === false) {
      investorIdList.push(Number(investor!.id))
    }
  })

  const getCurrentInvestment = Math.abs(
    _.sumBy(investorIrrTransactionsData?.getInvestorTransactions, 'amount')
  )

  useEffect(() => {
    let revenue = undefined
    const companyDetails = companyData?.getCompanyData?.companyData
    if (companyDetails?.financialData?.data && companyDetails.financialData?.data?.length > 0) {
      const yearsData = companyDetails.financialData.data.filter(
        (data) => data?.timelineType?.toLowerCase() === 'year'
      )

      if (yearsData && yearsData.length > 0) {
        yearsData.sort((a: any, b: any) => Number(b.year) - Number(a.year))
        revenue = yearsData[0]?.data.totalRevenue
      }
    }

    exitModelling.currentInvestmentDetails.valuation =
      companyData?.getCompanyData?.companyData?.valuation!
    exitModelling.currentInvestmentDetails.dilution = 0
    exitModelling.currentInvestmentDetails.holdingPercentage =
      _.sumBy(
        companyData?.getCompanyData?.investorData,
        (investor) => investor?.fdbHoldingPercentage || 0
      ) * 100
    exitModelling.currentInvestmentDetails.investment = getCurrentInvestment

    exitModelling.currentInvestmentDetails.revenue = revenue
      ? Number(revenue)
      : (companyData?.getCompanyData?.companyData?.valuation || 0) / 10
    exitModelling.companyFdbShares = companyData?.getCompanyData?.companyData?.totalFdbShares || 0
    exitModelling.currentInvestmentDetails.currentValue =
      _.sumBy(
        companyData?.getCompanyData?.investorData,
        (investorData) => investorData?.currentValue || 0
      ) || 0
    if (modelId === 0) {
      exitModelling.tableDetails = []
    }
    sendMessage(exitModelling)
  }, [companyData, getCurrentInvestment])

  function primaryHeader(heading: string, subHeading: string) {
    return (
      <>
        <div className='fs-base pt-4 fw-bold'>{heading}</div>
        <div className='text-gray-400 pt-2'>{subHeading}</div>
      </>
    )
  }

  function roundsToExitTableData(index: number, mainValue: string | number, key: string) {
    if (mainValue !== null && mainValue !== undefined) {
      exitModelling['tableDetails'][index][key] = mainValue
    }
    socket.emit('message', exitModelling)
    socket.on('message', (data) => {
      setExitModelling(JSON.parse(data))
    })
  }

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Header className='fw-bold fs-4'>Investment Details</Popover.Header>
      <Popover.Body>
        <div className='text-popover'>
          IRR :
          <span className='text-primary'>
            {irr === 0 || isDateEmpty === true ? 'NA' : `${Number(irr).toFixed(2)} %`}
          </span>
        </div>
        <div className='text-popover'>
          MOIC :
          <span className='text-primary'>
            {moic === 0 || isDateEmpty === true ? 'NA' : `${Number(moic).toFixed(2)} %`}
          </span>
        </div>
        {/* <div className='text-popover'>Liquidation preference - </div> */}
      </Popover.Body>
    </Popover>
  )

  function calculateOpacity(currentNumber: number, maxNumber: number) {
    return currentNumber / maxNumber
  }

  function calculateTextColor(currentNumber: number, maxNumber: number) {
    const value = Math.floor((currentNumber / maxNumber) * 256)
    return value > 180 ? `rgba(255,255, 255,1)` : `rgba(0,0, 0,1)`
  }

  type IRRListType = { date: Date; amount: number }
  function handleMouseEnter(value: number) {
    let irrList: IRRListType[] = []

    if (investorIrrTransactionsData?.getInvestorTransactions?.length! > 0) {
      investorIrrTransactionsData?.getInvestorTransactions?.forEach((transaction: any) => {
        irrList.push({
          date: new Date(transaction?.date?.split('T')[0]),
          amount: transaction?.amount,
        })
      })
    }
    let totalInvestmentAmount = _.sumBy(
      investorIrrTransactionsData?.getInvestorTransactions,
      'amount'
    )

    const elementsUpToActiveIndex = exitModelling.tableDetails.slice(0, activeIndex + 1)
    const dateList = elementsUpToActiveIndex.filter((element: any) => element.date === '')
    if (dateList.length > 0) {
      setIsDateEmpty(true)
    } else {
      setIsDateEmpty(false)
    }

    if (activeIndex === -1) {
      irrList = [...irrList, { date: new Date(), amount: value }]
      calculateIRR(irrList)
      calculateMoic(totalInvestmentAmount, value)
    } else {
      exitModelling.tableDetails.forEach((details: any, index: number) => {
        if (index < activeIndex) {
          if (index === 0) {
            irrList.push({
              date: details.date ? new Date(details.date) : new Date(),
              amount:
                Number(details.cumulativeInvestment) -
                Number(exitModelling?.currentInvestmentDetails?.investment),
            })
          } else {
            irrList.push({
              date: details.date ? new Date(details.date) : new Date(),
              amount:
                Number(details.cumulativeInvestment) -
                Number(exitModelling.tableDetails[index - 1]?.cumulativeInvestment),
            })
          }
        } else if (index === activeIndex) {
          if (index === 0) {
            irrList = [
              ...irrList,
              {
                date: details.date ? new Date(details.date) : new Date(),
                amount:
                  Number(details.cumulativeInvestment) -
                  Number(exitModelling?.currentInvestmentDetails?.investment),
              },
              {
                date: details.date ? new Date(details.date) : new Date(),
                amount: value,
              },
            ]
          } else {
            irrList = [
              ...irrList,
              {
                date: details.date ? new Date(details.date) : new Date(),
                amount:
                  Number(details.cumulativeInvestment) -
                  Number(exitModelling.tableDetails[index - 1]?.cumulativeInvestment),
              },
              {
                date: details.date ? new Date(details.date) : new Date(),
                amount: value,
              },
            ]
          }
        }

        if (irrList.length < 1) {
          setMoic(0)
        }
        if (index > activeIndex) return
        totalInvestmentAmount = totalInvestmentAmount + Number(details.investment)
      })

      calculateMoic(totalInvestmentAmount, value)

      calculateIRR(irrList)
    }
  }

  function calculateIRR(irrList: IRRListType[]) {
    if (irrList.length > 0) {
      let calculatedIrr = 0
      if (isNaN(convertRate(xirr(irrList).rate, RateInterval.Year))) {
        const values = irrList.map((value: any) => value.amount)
        const dates = irrList.map((date: any) => new Date(date.date))
        const irrValue = calculateXirr(values, dates)
        calculatedIrr = irrValue !== 'NA' ? irrValue * 100 : 0
      } else {
        calculatedIrr = convertRate(xirr(irrList).rate, RateInterval.Year) * 100
      }
      setIrr(calculatedIrr)
    }
  }

  function calculateMoic(totalInvestmentAmount: number, value: number) {
    const moicValue = value / totalInvestmentAmount
    setMoic(moicValue)
  }

  if (isLoading) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  function exitRevenueData() {
    return (
      <>
        {exitModelling?.heatMap?.map((valuationCap: number) => (
          <>
            <tr key={valuationCap}>
              <td className='fs-6 fw-bold text-center min-w-100px py-1 px-2 pe-0'>
                <div className=' rounded-1 py-1'>
                  {formatNumber({
                    value: valuationCap,
                    format: userCurrencyFormat,
                  })}
                </div>
              </td>
              {initialMultiplier.map((multiplier) => (
                <>
                  <td className='fs-6 fw-bold text-center min-w-100px px-2 pe-0'>
                    <div
                      className=' rounded-1 py-1'
                      style={{
                        background: `rgba(0, 151, 19, ${calculateOpacity(
                          Number(
                            (
                              (valuationCap *
                                multiplier *
                                (exitModelling?.finalHoldingPercentage || 0)) /
                              100
                            ).toFixed(2)
                          ),
                          Number(
                            (
                              (exitModelling?.heatMap[exitModelling?.heatMap.length - 1] *
                                initialMultiplier[initialMultiplier.length - 1] *
                                (exitModelling?.finalHoldingPercentage || 0)) /
                              100
                            ).toFixed(2)
                          )
                        )})`,
                        color: calculateTextColor(
                          Number(
                            (
                              (valuationCap *
                                multiplier *
                                (exitModelling?.finalHoldingPercentage || 0)) /
                              100
                            ).toFixed(2)
                          ),
                          Number(
                            (
                              (exitModelling?.heatMap[exitModelling?.heatMap.length - 1] *
                                initialMultiplier[initialMultiplier.length - 1] *
                                (exitModelling?.finalHoldingPercentage || 0)) /
                              100
                            ).toFixed(2)
                          )
                        ),
                      }}
                    >
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement='bottom'
                        rootCloseEvent='mousedown'
                        overlay={popover}
                      >
                        <div
                          data-toggle='tooltip'
                          title={Number(
                            (
                              (valuationCap *
                                multiplier *
                                (exitModelling?.finalHoldingPercentage || 0)) /
                              100
                            ).toFixed(2)
                          ).toLocaleString(userCurrencyFormat)}
                          onMouseEnter={() => {
                            handleMouseEnter(
                              Number(
                                (
                                  (valuationCap *
                                    multiplier *
                                    (exitModelling?.finalHoldingPercentage || 0)) /
                                  100
                                ).toFixed(2)
                              )
                            )
                          }}
                        >
                          {formatNumber({
                            value: Number(
                              (
                                (valuationCap *
                                  multiplier *
                                  (exitModelling?.finalHoldingPercentage || 0)) /
                                100
                              ).toFixed(2)
                            ),
                            format: userCurrencyFormat,
                          })}
                        </div>
                      </OverlayTrigger>
                    </div>
                  </td>
                </>
              ))}
            </tr>
          </>
        ))}
      </>
    )
  }

  return (
    <div>
      <div className='col-12 mt-2'>
        <div className='col d-flex flex-row justify-content-between'>
          <button
            type='button'
            onClick={() => {
              navigate(`/company/exitmodelling/HISSA/${hissaCompanyId}`)
            }}
            className='px-4 rounded border border-1 border-hover-primary border-secondary-txt bg-transparent h-38px fw-normal text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr021.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Back
          </button>
          <button
            type='button'
            onClick={() => {
              setLoading(true)
              mutateSaveModelling.mutate({
                input: {
                  modelName,
                  modellingDataId: initialData
                    ? Number(initialData?.modellingDataId)
                    : previousModelling?.modellingDataId,
                  investmentModellingDetails: exitModelling as ExitModellingDataModel,
                  hissaCompanyId: hissaCompanyId,
                  investorIds: investorIdList,
                },
              })
            }}
            // disabled={Number(modelId) === 0}
            className={`px-4 rounded h-38px fw-normal ms-4 text-white border-0 ${
              Number(1) === 0 ? 'disabled bg-primary-light ' : ' bg-primary '
            }`}
          >
            {' '}
            {!loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                <KTSVG
                  path='/media/icons/duotune/files/fil017.svg'
                  className='svg-icon-3 text-white'
                />{' '}
                Save Model
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
      <div className='card col-md-12 my-9'>
        <div className='card p-9'>
          <div className='card-title align-items-start flex-row my-0 justify-content-between '></div>

          <div className='d-flex justify-content-start'>
            {isEditName && (
              <>
                <div className='col-2'>
                  <InputBox
                    isDisabled={false}
                    name={'modelName'}
                    handleChange={(value: string) => {
                      setNewModelName(value)
                    }}
                    setFieldValue={(value: string) => {
                      setNewModelName(value)
                    }}
                    placeholder='Enter Model Name'
                    currency={''}
                    value={newModelName}
                    type='text'
                    autocomplete={false}
                  />
                </div>
                <div className='ps-2'>
                  <button
                    className='btn btn-sm btn-icon'
                    onClick={() => {
                      setModelName(newModelName)
                      setIsEditName(false)
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen037.svg'
                      className='svg-icon svg-icon-2x text-gray-600 text-hover-primary '
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon'
                    onClick={() => {
                      setModelName(initialData?.modelName || modelName)
                      setNewModelName(initialData?.modelName || modelName)
                      setIsEditName(false)
                    }}
                  >
                    <KTSVG
                      className='svg-icon svg-icon-2x text-gray-600 text-hover-primary '
                      path='/media/icons/duotune/general/gen034.svg'
                    />
                  </button>
                </div>
              </>
            )}
            {!isEditName && (
              <>
                <h1 className='pt-3 mb-0 fw-bold'>{modelName}</h1>
                <div className='ps-2'>
                  <button
                    className='btn btn-sm btn-icon'
                    onClick={() => {
                      setIsEditName(true)
                    }}
                  >
                    <span className='svg-icon svg-icon-2'>
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
          <p className='fs-8 text-gray-400'>Enter the parameters of the exit</p>

          <div className='card-header rounded py-2 px-0 fs-7'>
            <div className='row w-100'>
              <div className='d-flex flex-row px-0'>
                <div className='card col-md-12  my-2'>
                  <div className='row mb-9'>
                    <div className='col-12'>
                      <div className='row gx-9'>
                        <div className='card-body col-12 col-md-6 mb-md-0 mb-9 mb-md-0 mr-1'>
                          <div className=' card-title align-items-start flex-row my-0 justify-content-end '>
                            <button
                              type='button'
                              className='px-4 rounded border border border-primary bg-white  h-38px fw-normal ms-4 text-primary'
                              onClick={() => {
                                if (activeIndex === -1) setActiveCurrent(true)

                                exitModelling['tableDetails'].push({
                                  roundName: '',
                                  dilution: 0,
                                  valuation: 0,
                                  investment: 0, // readonly field
                                  holdingPercentage: 0,
                                  date: '',
                                  roundSize: 0, //readonly field
                                  revenue: 0,
                                  exitHere: false,
                                  isHoldingPercentageModified: false,
                                })
                                exitModelling['numberOfRounds'] =
                                  exitModelling['tableDetails'].length
                                sendMessage(exitModelling)
                              }}
                            >
                              + Add
                            </button>
                          </div>
                          <div className='card-header rounded  fs-7  justify-content-left px-0'>
                            <div className='table-responsive py-4 my-2'>
                              <table className='table align-start-0 gs-4 gy-4'>
                                <thead>
                                  <tr className='fw-bold text-gray-600 border-bottom-1 border-gray-500 border-bottom-dashed'>
                                    <th
                                      className='fs-base min-w-175px text-center '
                                      style={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 50,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      Parameters
                                    </th>
                                    <th
                                      className={'fs-6 fw-bold min-w-175px m-0  p-0 text-center'}
                                      style={{
                                        position: 'sticky',
                                        left: 175,
                                        backgroundColor: 'white',
                                        width: '25px',
                                        fontWeight: 700,
                                      }}
                                    >
                                      <div
                                        className={`px-2 py-4 ${
                                          activeCurrent
                                            ? 'text-primary border border border-growth-card border border-bottom-0 rounded rounded-bottom-0'
                                            : ''
                                        }`}
                                        style={{ fontWeight: 700 }}
                                      >
                                        Current
                                      </div>
                                    </th>

                                    {Array.from(
                                      { length: exitModelling.tableDetails.length },
                                      (_, index) => (
                                        <th
                                          key={index}
                                          className={'fs-base text-center px-0 pb-0 '}
                                        >
                                          <div
                                            className={`ps-9 py-4 d-flex align-items-center  ${
                                              index === activeIndex
                                                ? 'text-primary border border border-growth-card border-bottom-0  rounded  px-0 rounded-bottom-0 '
                                                : ''
                                            }`}
                                            style={{ height: '46px', fontWeight: 700 }}
                                          >
                                            <span className='pt-1 px-6'>Future R{index + 1}</span>
                                            <button
                                              className={`btn btn-xs btn-icon  border pl-7 ${
                                                index === activeIndex ? 'border-primary' : ''
                                              }`}
                                              style={{
                                                padding: '4px',
                                                width: '13px',
                                                height: '13px',
                                              }}
                                              type='button'
                                              data-bs-toggle='tooltip'
                                              title='Delete'
                                              onClick={() => {
                                                exitModelling.tableDetails[index].isDeleted = true
                                                sendMessage(exitModelling)
                                              }}
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/arrows/arr061.svg'
                                                className={`svg-icon-1 ${
                                                  index === activeIndex ? 'text-primary pt-0 ' : ''
                                                }`}
                                              />
                                            </button>
                                          </div>
                                        </th>
                                      )
                                    )}
                                  </tr>
                                </thead>
                                <tbody className='border-bottom border-gray-500 border-bottom-dashed '>
                                  <tr>
                                    <td
                                      className='py-5 text-center fw-bold'
                                      style={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 2,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      Valuation ({getSymbolOfCurrency(userCurrency)})
                                    </td>
                                    <td
                                      className={'fs-6 fw-bold w-25 p-0 text-center'}
                                      style={{
                                        position: 'sticky',
                                        left: 175,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <div
                                        data-toggle='tooltip'
                                        className={`px-2 py-5 ${
                                          activeCurrent
                                            ? ' border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                            : ''
                                        }`}
                                        title={exitModelling?.currentInvestmentDetails?.valuation?.toLocaleString(
                                          userCurrencyFormat
                                        )}
                                      >
                                        {formatNumber({
                                          value:
                                            exitModelling?.currentInvestmentDetails?.valuation ??
                                            '-',
                                          format: userCurrencyFormat,
                                        })}
                                      </div>
                                    </td>

                                    {Array.from(
                                      { length: exitModelling.tableDetails.length },
                                      (_, index) => (
                                        <td className={'fs-6 fw-bold w-25 p-0'}>
                                          <div
                                            className={`px-2 py-4 ${
                                              index === activeIndex
                                                ? 'text-primary border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                                : ''
                                            }`}
                                          >
                                            <input
                                              defaultValue={
                                                exitModelling.tableDetails[index]?.valuation || ''
                                              }
                                              type='number'
                                              className=' form-control form-control-xs  form-control-solid h-25px text-center'
                                              onChange={(e) => {
                                                roundsToExitTableData(
                                                  index,
                                                  e.target.value,
                                                  'valuation'
                                                )
                                              }}
                                            />
                                          </div>
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td
                                      className='pt-5 fw-bold text-center'
                                      style={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 1,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      Dilution
                                    </td>
                                    <td
                                      className={'fs-6 fw-bold w-25 p-0 text-center'}
                                      style={{
                                        position: 'sticky',
                                        left: 175,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <div
                                        className={`px-2 py-5 ${
                                          activeCurrent
                                            ? 'border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                            : ''
                                        }`}
                                      >
                                        -
                                      </div>
                                    </td>
                                    {Array.from(
                                      { length: exitModelling.tableDetails.length },
                                      (_, index) => (
                                        <td className={'fs-6 fw-bold w-25 p-0 '}>
                                          <div
                                            className={`px-2 py-4 ${
                                              index === activeIndex
                                                ? 'text-primary border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                                : ''
                                            }`}
                                          >
                                            <input
                                              type='number'
                                              defaultValue={
                                                exitModelling.tableDetails[index]?.dilution || ''
                                              }
                                              className=' form-control form-control-xs  form-control-solid h-25px text-center'
                                              onChange={(e) => {
                                                if (
                                                  Number(e.target.value) >= 0 &&
                                                  Number(e.target.value) < 100
                                                ) {
                                                  roundsToExitTableData(
                                                    index,
                                                    e.target.value,
                                                    'dilution'
                                                  )
                                                } else {
                                                  e.target.value =
                                                    exitModelling.tableDetails[index]?.dilution ||
                                                    ''
                                                }
                                              }}
                                            />
                                          </div>
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td
                                      className='text-center'
                                      style={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 1,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      {primaryHeader('Ownership (%)', 'Investment Amt.')}
                                    </td>
                                    <td
                                      className={'fs-6 fw-bold w-25 p-0 text-center'}
                                      style={{
                                        position: 'sticky',
                                        left: 175,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <div
                                        data-toggle='tooltip'
                                        className={`px-2 pt-8  ${
                                          activeCurrent
                                            ? 'border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                            : ''
                                        }`}
                                        title={Number(
                                          exitModelling?.currentInvestmentDetails?.holdingPercentage
                                        ).toLocaleString(userCurrencyFormat)}
                                      >
                                        {Number(
                                          exitModelling?.currentInvestmentDetails?.holdingPercentage
                                        ).toFixed(2) ?? '-'}
                                      </div>

                                      <div
                                        data-toggle='tooltip'
                                        className={`px-2 pb-4 pt-2 text-gray-400 ${
                                          activeCurrent
                                            ? 'border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                            : ''
                                        }`}
                                        title={exitModelling?.currentInvestmentDetails?.investment.toLocaleString(
                                          userCurrencyFormat
                                        )}
                                      >
                                        {formatNumber({
                                          value:
                                            exitModelling?.currentInvestmentDetails?.investment ??
                                            '-',
                                          format: userCurrencyFormat,
                                        })}
                                      </div>
                                    </td>
                                    {Array.from(
                                      { length: exitModelling.tableDetails.length },
                                      (_, index) => (
                                        <td className={'fs-6 fw-bold w-25 p-0'}>
                                          <div
                                            className={`px-2 pt-6  ${
                                              index === activeIndex
                                                ? 'text-primary border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                                : ''
                                            }`}
                                          >
                                            <input
                                              type='number'
                                              defaultValue={
                                                exitModelling.tableDetails[index]
                                                  ?.holdingPercentage || ''
                                              }
                                              className=' form-control form-control-xs  form-control-solid h-25px text-center expand-from-center'
                                              onChange={(e) => {
                                                exitModelling.tableDetails[
                                                  index
                                                ].isHoldingPercentageModified = true
                                                if (
                                                  Number(e.target.value) >= 0 &&
                                                  Number(e.target.value) < 100
                                                ) {
                                                  roundsToExitTableData(
                                                    index,
                                                    e.target.value,
                                                    'holdingPercentage'
                                                  )
                                                } else {
                                                  e.target.value =
                                                    exitModelling.tableDetails[index]
                                                      ?.holdingPercentage || ''
                                                }
                                              }}
                                            />
                                          </div>
                                          <div
                                            data-toggle='tooltip'
                                            className={`px-2 pt-2 pb-4 text-center text-gray-400 ${
                                              index === activeIndex
                                                ? ' border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                                : ''
                                            }`}
                                            title={exitModelling?.tableDetails[
                                              index
                                            ].cumulativeInvestment?.toLocaleString(
                                              userCurrencyFormat
                                            )}
                                          >
                                            {formatNumber({
                                              value:
                                                exitModelling?.tableDetails[index]
                                                  ?.cumulativeInvestment ?? '-',
                                              format: userCurrencyFormat,
                                            })}
                                          </div>
                                        </td>
                                      )
                                    )}{' '}
                                  </tr>
                                  <tr>
                                    <td
                                      className='pt-5 text-center fw-bold'
                                      style={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 1,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      Revenue( {getSymbolOfCurrency(userCurrency)} )
                                    </td>
                                    <td
                                      className={'fs-6 fw-bold w-25 p-0 text-center'}
                                      style={{
                                        position: 'sticky',
                                        left: 175,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <div
                                        data-toggle='tooltip'
                                        className={`px-2 py-5 ${
                                          activeCurrent
                                            ? 'border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                            : ''
                                        }`}
                                        title={exitModelling?.currentInvestmentDetails?.revenue.toLocaleString(
                                          userCurrencyFormat
                                        )}
                                      >
                                        {formatNumber({
                                          value:
                                            exitModelling?.currentInvestmentDetails?.revenue ?? '-',
                                          format: userCurrencyFormat,
                                        })}
                                      </div>
                                    </td>
                                    {Array.from(
                                      { length: exitModelling.tableDetails.length },
                                      (_, index) => (
                                        <td className={'fs-6 fw-bold w-25 p-0'}>
                                          <div
                                            className={`px-2 py-4 ${
                                              index === activeIndex
                                                ? 'border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                                : ''
                                            }`}
                                          >
                                            <input
                                              type='number'
                                              defaultValue={
                                                exitModelling.tableDetails[index]?.revenue || ''
                                              }
                                              className=' form-control form-control-xs  form-control-solid h-25px text-center expand-from-center'
                                              onChange={(e) => {
                                                roundsToExitTableData(
                                                  index,
                                                  e.target.value,
                                                  'revenue'
                                                )
                                              }}
                                            />
                                          </div>
                                        </td>
                                      )
                                    )}{' '}
                                  </tr>
                                  <tr>
                                    <td
                                      className=' text-center fw-bold'
                                      style={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 1,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <div className='fs-base pt-4 '>Round Date</div>
                                      <div className='text-gray-400 pt-2'>
                                        {`Round Size (${getSymbolOfCurrency(userCurrency)})`}
                                      </div>
                                    </td>
                                    <td
                                      className={'fs-6 fw-bold w-25 px-0 pt-0 pb-0 text-center'}
                                      style={{
                                        position: 'sticky',
                                        left: 175,
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <div
                                        className={`px-2 pt-8  ${
                                          activeCurrent
                                            ? 'border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                            : ''
                                        }`}
                                      >
                                        {new Date()
                                          .toISOString()
                                          .split('T')[0]
                                          .split('-')
                                          .reverse()
                                          .join('-')}
                                      </div>

                                      <div
                                        className={`px-2 pb-4 pt-2 ${
                                          activeCurrent
                                            ? 'border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                            : ''
                                        }`}
                                      >
                                        -
                                      </div>
                                    </td>

                                    {Array.from(
                                      { length: exitModelling.tableDetails.length },
                                      (_, index) => (
                                        <td className={'fs-6 fw-bold w-25 px-0 pt-0 pb-0'}>
                                          <div
                                            className={`px-2 pt-6 ${
                                              index === activeIndex
                                                ? 'text-primary border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                                : ''
                                            }`}
                                          >
                                            <input
                                              type='date'
                                              defaultValue={
                                                exitModelling.tableDetails[index]?.date || ''
                                              }
                                              className=' form-control form-control-xs  form-control-solid h-25px text-center expand-from-center'
                                              onChange={(e) => {
                                                roundsToExitTableData(index, e.target.value, 'date')
                                              }}
                                            />
                                          </div>
                                          <div
                                            className={`px-2 pt-2 pb-4 text-center ${
                                              index === activeIndex
                                                ? ' border border border-growth-card border-top-0 border-bottom-0 rounded  px-0  rounded-top-0 rounded-bottom-0'
                                                : ''
                                            }`}
                                            title={exitModelling?.tableDetails[
                                              index
                                            ]?.roundSize?.toLocaleString(userCurrencyFormat)}
                                          >
                                            {formatNumber({
                                              value:
                                                exitModelling?.tableDetails[index]?.roundSize ??
                                                '-',
                                              format: userCurrencyFormat,
                                            })}
                                          </div>
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td
                                      className='pt-0'
                                      style={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 1, // To make sure it's above other columns
                                        backgroundColor: 'white', // Ensures it appears above other cells
                                      }}
                                    ></td>
                                    <td
                                      className='fs-6 fw-bold pt-0 text-center px-0 '
                                      style={{
                                        position: 'sticky',
                                        left: 175,
                                        zIndex: 1, // To make sure it's above other columns
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <button
                                        type='submit'
                                        className={`btn text-nowrap btn-sm ${
                                          activeCurrent ? 'bg-growth-card' : 'bg-gray-100'
                                        } bg-hover-growth-card text-active-primary text-hover-white px-0 text-gray-400  rounded-top-0`}
                                        onClick={() => {
                                          setActiveIndex(-1)

                                          setActiveCurrent(true)
                                          exitModelling.currentInvestmentDetails.exitHere = true
                                          for (let round of exitModelling.tableDetails) {
                                            round.exitHere = false
                                          }
                                          sendMessage(exitModelling)
                                        }}
                                        style={{ width: '100%' }} // Adjust width as needed
                                      >
                                        Exit Here
                                      </button>
                                    </td>

                                    {Array.from(
                                      { length: exitModelling.tableDetails.length },
                                      (_, index) => (
                                        <td className='fs-6 fw-bold pt-0 text-center px-0 '>
                                          <button
                                            type='submit'
                                            className={`btn text-nowrap btn-sm ${
                                              index === activeIndex
                                                ? 'bg-growth-card'
                                                : 'bg-gray-100'
                                            } bg-hover-growth-card text-active-primary text-hover-white px-0 text-gray-400  rounded-top-0`}
                                            onClick={() => {
                                              setActiveIndex(index)
                                              setActiveCurrent(false)
                                              exitModelling.currentInvestmentDetails.exitHere =
                                                false

                                              for (
                                                let i = 0;
                                                i < exitModelling.tableDetails.length;
                                                i++
                                              ) {
                                                if (i === index) {
                                                  exitModelling.tableDetails[i].exitHere = true
                                                } else {
                                                  exitModelling.tableDetails[i].exitHere = false
                                                }
                                              }
                                              sendMessage(exitModelling)
                                            }}
                                            style={{ width: '100%' }} // Adjust width as needed
                                          >
                                            Exit Here
                                          </button>
                                        </td>
                                      )
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className='d-flex'>
                            <div className='required'></div>
                            <p className='text-gray-400'>
                              To view the IRR for a different round, enter the corresponding date in
                              the field provided.
                            </p>
                          </div>
                        </div>
                        <div className='col-12 col-md-6 mb-md-0 mb-9 mb-md-0 card-body rounded shadow-xs overflow-scroll '>
                          <div className='card-header rounded py-2 fs-7 p-0 justify-content-left'>
                            <div className=' float-start'>
                              <h2 className='fw-bold'>
                                {`Exit Returns as of ${
                                  exitModelling.tableDetails[activeIndex]?.date ||
                                  new Date().toISOString().split('T')[0]
                                }`}
                              </h2>

                              <div className='fs-base row'>
                                <div className='col d-flex'>
                                  <div>
                                    <span className='text-gray-400'>
                                      Final Holding Percentage is :
                                    </span>
                                    <span
                                      className='fs-2 fw-bold'
                                      data-toggle='tooltip'
                                      data-placement='top'
                                      title={exitModelling?.finalHoldingPercentage?.toLocaleString(
                                        userCurrencyFormat
                                      )}
                                    >
                                      {Number(exitModelling?.finalHoldingPercentage).toFixed(2)}
                                      %,
                                    </span>
                                  </div>
                                  <div className='px-1 py-0'>
                                    <span className='text-gray-400'>Total Investment is:</span>
                                    <span
                                      className='fs-2 '
                                      data-toggle='tooltip'
                                      data-placement='top'
                                      title={exitModelling?.finalInvestmentAmount?.toLocaleString(
                                        userCurrencyFormat
                                      )}
                                    >
                                      {formatNumber({
                                        value: exitModelling?.finalInvestmentAmount ?? '-',
                                        format: userCurrencyFormat,
                                      })}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='row py-4 my-8 justify-content-center'>
                                <div className='float-end text-end font-bold fs-4 '>Multiplier</div>

                                <div className='col d-flex py-3'>
                                  <div
                                    className='text-start font-bold fs-4 my-10 h-full '
                                    style={{
                                      writingMode: 'vertical-rl',
                                      transform: 'rotate(180deg)',
                                    }}
                                  >
                                    Revenue in{' '}
                                    <span style={{ fontFamily: 'arial' }}>
                                      ({getSymbolOfCurrency(userCurrency)})
                                    </span>
                                  </div>
                                  <div className='col'>
                                    <div>
                                      <div className='d-flex  align-items-center justify-content-center'>
                                        <table className='table align-start-0 gs-4 gy-0'>
                                          <thead>
                                            <thead>
                                              <tr className='fw-bold '>
                                                <th className='fs-base min-w-25px  text-center  pe-0'></th>
                                                {initialMultiplier.map((val, index) => (
                                                  <th className='fs-base rounded-2   text-center px-2 pe-0'>
                                                    <div className='d-flex  align-items-center pb-1'>
                                                      <input
                                                        type='number'
                                                        defaultValue={val}
                                                        className='w-100   text-center border-1 rounded-start border-gray-900  border-end-0'
                                                        style={{
                                                          outline: 'none', // Remove focus outline
                                                          boxShadow: 'none', // Remove focus shadow
                                                        }}
                                                        onChange={(e) => {
                                                          let newMultiplier = initialMultiplier.map(
                                                            (multiplier, i) => {
                                                              if (i === index) {
                                                                return Number(e.target.value)
                                                              } else {
                                                                return multiplier
                                                              }
                                                            }
                                                          )
                                                          setInitialMultiplier(newMultiplier)
                                                        }}
                                                      />
                                                      <span
                                                        className='rounded-end border pe-1 border-start-0 border-2 border-gray-900 '
                                                        style={{
                                                          lineHeight: '1.4',
                                                          height: '25px',
                                                        }}
                                                      >
                                                        <svg
                                                          width='15'
                                                          height='12'
                                                          viewBox='0 0 10 10'
                                                          fill='none'
                                                          xmlns='http://www.w3.org/2000/svg'
                                                        >
                                                          <path
                                                            d='M6.03 3.5L6.5 3.97L1.96 8.5H1.5V8.04L6.03 3.5ZM7.83 0.5C7.705 0.5 7.575 0.55 7.48 0.645L6.565 1.56L8.44 3.435L9.355 2.52C9.55 2.325 9.55 2 9.355 1.815L8.185 0.645C8.085 0.545 7.96 0.5 7.83 0.5ZM6.03 2.095L0.5 7.625V9.5H2.375L7.905 3.97L6.03 2.095Z'
                                                            fill='#464E5F'
                                                          />
                                                        </svg>
                                                      </span>
                                                    </div>
                                                  </th>
                                                ))}
                                              </tr>
                                            </thead>
                                            <tbody>{exitRevenueData()}</tbody>
                                          </thead>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <button
              type='submit'
              className='btn btn-primary m-2 float-end'
              onClick={() => {
                setShowModal(true)
              }}
            >
              Share
            </button> */}
            {/* <button
              type='submit'
              className='btn btn-primary m-2 float-end'
              onClick={async () => {
                mutateSaveModelling.mutate({
                  input: {
                    modelName,
                    modellingDataId: initialData
                      ? Number(initialData?.modellingDataId)
                      : previousModelling?.modellingDataId,
                    investmentModellingDetails: exitModelling,
                    hissaCompanyId: hissaCompanyId,
                    investorIds: investorIdList,
                  },
                })
                Toast('Modelling Saved Successfully', ToastType.success)
              }}
            >
              Save Model
            </button> */}
          </div>

          {showModal && (
            <EmailInvestmentModelling
              showModal={showModal}
              handleClose={() => setShowModal(false)}
              investmentModellingData={exitModelling}
              investorId={invId}
            />
          )}
        </div>
      </div>
    </div>
  )
}
